<template>
   <div>
      <el-date-picker v-model="rport.from"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择开始日期">
      </el-date-picker>
      <el-date-picker v-model="rport.to"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择结束日期">
      </el-date-picker>
      <el-input placeholder="请输入姓名或报告类型"
                suffix-icon="el-icon-search"
                v-model="rport.findKey">
      </el-input>
      <el-button @click="search"
                 type="primary">查询</el-button>
      <el-button @click="fromCommit"
                 type="danger">选择数据生成报告</el-button>
      <div>
         <el-table :data="reverseSum.slice((this.currentPage-1)*this.PageSize,this.currentPage*this.PageSize)"
                   border
                   style="width: 100%"
                   :default-sort="{prop: 'dateTime', order: 'descending'}"
                   @selection-change="selectionLineChangeHandle">
            <el-table-column type="selection"
                             width="55">
            </el-table-column>
            <el-table-column prop="cname"
                             label="患者姓名"
                             align="center"
                             width="100">
            </el-table-column>
            <el-table-column prop="doctor"
                             label="主治医生"
                             align="center"
                             width="100">
            </el-table-column>
            <el-table-column prop="rtName"
                             label="方案名称"
                             align="center"
                             width="150">
            </el-table-column>
            <el-table-column prop="dateTime"
                             label="报告时间"
                             align="center"
                             width="250"
                             sortable>
               <template slot-scope="scope">{{
            scope.row.dateTime | formatDate
          }}</template>
            </el-table-column>

            <el-table-column label="报告结果"
                             prop="solution"
                             align="center"
                             width="220">
            </el-table-column>
            <el-table-column label="方案时间"
                             align="center"
                             width="150"
                             prop="data">
               <template slot-scope="scope">{{
            scope.row.data.split(",")[0]
          }}</template>
            </el-table-column>
            <el-table-column label="光照强度"
                             align="center"
                             width="150"
                             prop="data">
               <template slot-scope="scope">{{
            scope.row.data.split(",")[1]
          }}</template>
            </el-table-column>
            <el-table-column label="就诊卡号"
                             align="center"
                             width="200"><template slot-scope="scope">{{
            scope.row.cid | hideMiddle
          }}</template>
            </el-table-column>
            <el-table-column fixed="right"
                             label="操作"
                             width="300"
                             align="center">
               <template slot-scope="scope">
                  <el-button type="primary"
                             size="mini"
                             icon="el-icon-view"
                             @click="
                toReport(
                  scope.row.cid,
                  scope.row.id,
                  scope.row.etId,
                  scope.row.cname
                )
              ">查看</el-button>
                  <el-button @click="toUpdateBtn(scope.row.id)"
                             size="mini"
                             type="success"
                             icon="el-icon-view">编辑</el-button>
                  <el-button @click="delBnt(scope.row.id)"
                             size="mini"
                             type="danger"
                             icon="el-icon-circle-close">删除</el-button>

               </template>
            </el-table-column>
         </el-table>
         <el-pagination @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="this.currentPage"
                        :page-sizes="this.pageSizes"
                        :page-size="this.PageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="reverseSum.length">
         </el-pagination>

      </div>
   </div>
</template>

<script>
import { formatDate } from "../common/date"; // 在组件中引用date.js
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers(
   "patient"
);
export default {
   data () {
      return {
         page: 1, //当前页码
         count: 0, //总纪录条数
         PageSize: 10,
         currentPage: 1,
         pageSizes: [10, 15, 20, 30],
         times: [],
         strength: "",
         findKey: "",
         value1: "",
         value2: "",
         searchData: [],
         dialogVisible: false,
         update: {},
         dataonLineListSelections: [],
      };
   },
   mounted () {
      this.rport
   },
   updated () { this.rport },
   created () { this.search() },
   filters: {
      hideMiddle (val) {
         return `${val.substring(0, 3)}****${val.substring(val.length - 3)}`;
      },
      formatDate (time) {
         let date = new Date(time);
         return formatDate(date, "yyyy-MM-dd-hh:mm:ss");
      },
   },
   computed: {
      ...mapState(["VRRport", "rport"]),
      reverseSum () {
         return this.VRRport.reverse();
      },
   },
   methods: {
      ...mapActions(["getReoprtALl", "delVRone"]),
      // 通过关键字查询患者
      async search () {
         this.getReoprtALl()

      },
      // 选择数据
      selectionLineChangeHandle (val) {
         this.dataonLineListSelections = val;
      },


      //提交
      fromCommit () {
         let arr = this.dataonLineListSelections;
         var everyResult = arr.every(item => item.cname === arr[0].cname);
         if (everyResult === false) {
            return this.$message({
               type: "info",
               message: "数据生成失败，请添加同一位患者！",
            });

         }

         else {
            localStorage.setItem("arrdata", JSON.stringify(arr));
            this.$router.push("/Treatmentreport");
         }
      },
      toReport (id, cid, etId) {
         this.$router.push(`/OneTreatmentreport/${id}/${cid}`);
      },

      // 删除报告
      delBnt (id) {
         this.getReoprtALl()
         // 删除提示框
         this.$confirm("此操作将永久删除该数据，是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "danger",
         })
            .then(() => {
               this.$message({
                  type: "success",
                  message: "删除成功！",
               });
               this.delVRone(id);
               this.getReoprtALl()
            })
            .catch(() => {
               this.$message({
                  type: "info",
                  message: "已取消删除",
               });
            });
      },
      // 修改报告
      toUpdateBtn (id) {
         this.$router.push(`/UpdateReport/${id}`)

      },
      handleEdit (index, row) {
         console.log(index, row);//这里可打印出每行的内容
         //点击编辑
         this.dialogFormVisible = true //显示弹框
         this.editForm = Object.assign({}, row); // editForm是Dialog弹框的data
         //显示编辑界面

      },
      // 前端分页
      handleDelete (index, row) {
         console.log(index, row);
      },
      handleSizeChange (val) {
         // 改变每页显示的条数
         this.PageSize = val
         // 注意：在改变每页显示的条数时，要将页码显示到第一页
         this.currentPage = 1
      },
      // 显示第几页
      handleCurrentChange (val) {
         // 改变默认的页数
         this.currentPage = val
      },
   }

};
</script>

<style lang="scss" scoped>
.el-input {
   width: 200px;
}
.el-pagination {
   margin-top: 10px;
   text-align: center;
}
</style>
